<template>
  <div :id="wrapperId" class="typeahead">
    <input
        :id="inputId"
        class="typeahead-input form-control"
        type="text"
        :placeholder="placeholder"
        aria-label="book search input"
        v-model="input"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
        @keydown.down.prevent="onArrowDown"
        @keydown.up.prevent="onArrowUp"
        @keydown.enter.tab.prevent="selectCurrentSelection"
        autocomplete="off"
    />
    <div v-if="isListVisible" class="typeahead-list">
      <div class="row">
        <div class="col-md-8 col-12">
          <ul class="search-results">
            <li class="search-result-item" v-for="(book, i) in filteredItems" :key="i">
              <search-result :book="book" @click="chooseBook(book)"/>
            </li>
          </ul>
        </div>

        <div class="col-md-4 col-12">
          <div class="card">
            <p>{{ $t('typeahead.cantFind') }}</p>
            <button @click="newBook" class="btn mr-3 btn-sm btn-primary btn-new-book">{{ $t('typeahead.tellUs') }}</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {defineComponent, nextTick} from 'vue';
import bookService from "@/app/services/bookService";
import PersistentStorage from "@/core/store/PersistentStorage";
import SearchResult from "@/app/components/Search/SearchResult";
import eventBus from "@/core/services/eventBus";
import logService from "@/app/services/LogService";
import Debounce from "@/core/helpers/debounce";

const debouncer = new Debounce();

export default defineComponent({
  name: 'TypeAhead',
  components: {SearchResult},
  emits: ['onInput', 'onFocus', 'onBlur', 'selectItem'],
  props: {
    id: {
      type: String,
    },
    placeholder: {
      type: String,
      default: '',
    },
    items: {
      type: Array
    },
    defaultItem: {
      default: null,
    },
    itemProjection: {
      type: Function,
      default(item) {
        return item;
      },
    },
    minInputLength: {
      type: Number,
      default: 2,
      validator: (prop) => {
        return prop >= 0;
      },
    },
    languages: {
      default: []
    }
  },
  mounted() {
    if (this.defaultItem !== undefined && this.defaultItem !== null) {
      this.selectItem(this.defaultItem);
    }
  },
  data() {
    return {
      inputId: this.id || `typeahead_${(Math.random() * 1000).toFixed()}`,
      input: '',
      isInputFocused: false,
      currentSelectionIndex: 0,
      filteredItems: []
    };
  },
  methods: {
    chooseBook(book) {
      nextTick(() => {
        this.input = '';
        this.filteredItems = [];

        // Check to see if we already have this book in our reading log. If we do, open the dialog for that one instead
        // of the search result.
        const bookInstance = logService.findBookByIsbn(book.isbn);
        if (book.bookshelf_sku != null) {
          eventBus.$emit("bookshelfBook", book);
          return;
        } else if (bookInstance != null) {
          eventBus.$emit("logReading", bookInstance, bookInstance.book);
        } else {
          eventBus.$emit("logReading", book);
        }
      });
    },
    newBook() {
      nextTick(() => {
        this.input = '';
        this.filteredItems = [];
        eventBus.$emit("logReading", null);
      });
    },
    onInput() {
      debouncer.debounce(() => {
        const results_limit = 25;
        if (this.input != '') {
          let lang = null;
          if (this.languages.length == 1) lang = this.languages[0];
          bookService.searchTitles(this.input, results_limit, lang).then(results => {
            if (this.languages.length === 1) {
              let filteredResults = results.data.filter((result) => result.language === this.languages[0]);
              this.filteredItems = filteredResults;
              // FIXME: This appears to be incorrect... Why return here and skip emitting the event below, but only if one language is selected?
              return;
            } else {
              // AR-339: 5. English titles appear first
              this.filteredItems = results.data;
              this.filteredItems.sort((a, b) => {
                if (a.language === "en" && b.language !== "en") {
                  return -1;
                } else if (b.language === "en" && a.language !== "en") {
                  return 1;
                }
                return 0;
              });
            }
          });
        }

        // FIXME: Also I think this is broken. this.filteredItems should be empty here, since searchTitles() above
        // is async.
        if (this.isListVisible && this.currentSelectionIndex >= this.filteredItems.length) {
          this.currentSelectionIndex = (this.filteredItems.length || 1) - 1;
        }
        this.$emit('onInput', {input: this.input, items: this.filteredItems});
      });
    },
    onFocus() {
      this.isInputFocused = true;
      this.$emit('onFocus', {input: this.input, items: this.filteredItems});
    },
    onBlur() {
      setTimeout(() => {
        this.isInputFocused = false;
        this.$emit('onBlur', {input: this.input, items: this.filteredItems});
      }, 300);
    },
    onArrowDown() {
      if (this.isListVisible && this.currentSelectionIndex < this.filteredItems.length - 1) {
        this.currentSelectionIndex++;
      }
      this.scrollSelectionIntoView();
    },
    onArrowUp() {
      if (this.isListVisible && this.currentSelectionIndex > 0) {
        this.currentSelectionIndex--;
      }
      this.scrollSelectionIntoView();
    },
    scrollSelectionIntoView() {
      setTimeout(() => {
        const list_node = document.querySelector(`#${this.wrapperId} .typeahead-list`);
        const active_node = document.querySelector(`#${this.wrapperId} .typeahead-list-item.typeahead-list-item-active`);
        if (!(active_node.offsetTop >= list_node.scrollTop && active_node.offsetTop + active_node.offsetHeight < list_node.scrollTop + list_node.offsetHeight)) {
          let scroll_to = 0;
          if (active_node.offsetTop > list_node.scrollTop) {
            scroll_to = active_node.offsetTop + active_node.offsetHeight - list_node.offsetHeight;
          } else if (active_node.offsetTop < list_node.scrollTop) {
            scroll_to = active_node.offsetTop;
          }
          list_node.scrollTo(0, scroll_to);
        }
      });
    },
    selectCurrentSelection() {
      return;
    },
    selectItem(item) {
      return;
    },
    escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    },
    boldMatchText(text) {
      const regexp = new RegExp(`(${this.escapeRegExp(this.input)})`, 'ig');
      return text.replace(regexp, '<strong>$1</strong>');
    },
  },
  computed: {
    wrapperId() {
      return `${this.inputId}_wrapper`;
    },
    isListVisible() {
      return this.isInputFocused;// && this.input.length >= this.minInputLength && this.filteredItems.length;
    },
    currentSelection() {
      return this.isListVisible && this.currentSelectionIndex < this.filteredItems.length ? this.filteredItems[this.currentSelectionIndex] : undefined;
    },
  },
  watch: {
    languages() {
      this.onInput();
    }
  },
});
</script>

<style scoped lang="scss">
.typeahead {
  container: typeahead / inline-size;
  position: relative;
  width: 100%;
  .search-results {
    list-style: none;
    padding-left: 0;
    width: 100%;
    @media screen and (max-width: 400px) {
      padding-left: 0;
    }
    .search-result-item {
      padding: 2px 15px;
      &:hover {
        background-color: #f3f3f3;
        cursor: pointer;
        border-radius: 8px;
        width: 100%;
      }
    }
  }
  /* Chrome & Safari Scrollbars */
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #A1A5B7;
    border-radius: 8px;
  }
    /* Firefox scrollbar */
  * {
        scrollbar-color: #A1A5B7 transparent;
  }
}

.typeahead > input {
  margin-bottom: 0;
  width: 100%;
  height: 40px;
  padding: 1.5em 2.75em;
  font-size: 14px;
  border-radius: 0 24px 24px 0;
  @media screen and (max-width: 600px) {
      border-radius: 24px 24px 24px 24px;
  }
}
.typeahead .typeahead-list {
  position: absolute;
  width: 100%;
  min-height: 150px;
  max-height: 500px;
  overflow-y: auto;
  border: solid 1px #E4E6EF;
  z-index: 9;
  background: #FFF;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 1px 1px 5px 2px #f0f3f6;
  top: calc(100% + 8px);

  @media only screen and (max-width: 350px) {
    &>.row {
      display: block;
      &>div {
        width: 100%;
      }
    }
  }
}

.typeahead-list .card {
  background: #f3f7fa;
  padding: 10px;
  text-align: center;
  position: relative;
  top: 10px;
  right: 10px;

  p {
    margin-bottom: 10px;
    font-weight: 500;
  }
  .btn-new-book {
    padding: 8px 4px!important;
  }
}

@container typeahead (max-width: 600px) {
  .typeahead > input {
    font-size: 13px;
  }
}

</style>