
import TypeAhead from "@/app/components/Search/TypeAhead.vue";

export default {
  components: {
    TypeAhead
  },
  data() {
    return {
      searchTerms: '',
      searchResults: [],
      dropdownLanguages: ['en', 'es'],
      showLangFilter: false
    }
  },
  methods: {
    toggleLangFilter() {
      this.showLangFilter = !this.showLangFilter;
    },
    hideMenu(event) {
      if (!event?.currentTarget.contains(event.relatedTarget)) {
        this.showLangFilter = false;
      }
    }
  }
}
