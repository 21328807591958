
import { defineComponent } from "vue";
import authenticationService from "@/core/services/AuthenticationService";

export default defineComponent({
  name: "topbar",
  components: {
  },
  computed: {
    isLoggedIn() {
      return authenticationService.isLoggedIn();
    }
  }
});
