import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex bd-highlight book-summary" }
const _hoisted_2 = { class: "book-cover-column" }
const _hoisted_3 = { class: "flex-grow-1 book-info" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "author mb-2" }
const _hoisted_6 = {
  key: 2,
  src: "/media/icons/bookshelf-icon.svg",
  style: {width: '20px'}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_book_cover = _resolveComponent("book-cover")!
  const _component_level_badge = _resolveComponent("level-badge")!
  const _component_steps_read = _resolveComponent("steps-read")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_book_cover, { book: $props.book }, null, 8, ["book"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", null, [
        ($props.book?.title)
          ? (_openBlock(), _createElementBlock("h3", _hoisted_4, _toDisplayString($options.formatTitle($props.book.title)), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, _toDisplayString($props.book?.author), 1),
        ($props.hideStats != true)
          ? (_openBlock(), _createBlock(_component_level_badge, {
              key: 1,
              level: $options.readingLevel
            }, null, 8, ["level"]))
          : _createCommentVNode("", true),
        ($props.book?.bookshelf_sku != null && $options.hasAccess)
          ? (_openBlock(), _createElementBlock("img", _hoisted_6))
          : _createCommentVNode("", true),
        ($props.hideStats != true)
          ? (_openBlock(), _createBlock(_component_steps_read, {
              key: 3,
              steps: $props.book?.total_steps
            }, null, 8, ["steps"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}