
import MilestoneBadge from "@/app/components/Stats/MilestoneBadge.vue";
export default {
  components: {MilestoneBadge},
  props: ['steps'],
  data() {
    return {
      milestones: [100, 200, 300, 400, 500, 600, 700, 800]
    }
  }
}
