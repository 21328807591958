import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "milestone-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_milestone_badge = _resolveComponent("milestone-badge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.milestones, (milestone) => {
      return (_openBlock(), _createBlock(_component_milestone_badge, {
        key: milestone,
        "total-steps": $props.steps,
        "step-number": milestone
      }, null, 8, ["total-steps", "step-number"]))
    }), 128))
  ]))
}