
import { defineComponent } from "vue";
import { footerWidthFluid } from "@/core/helpers/config";
import moment from 'moment-with-locales-es6';

export default defineComponent({
  name: "Footer",
  setup() {
    return {
      footerWidthFluid,
    };
  },
  computed: {
    currentYear() {
      return moment().year();
    }
  }
});
