<template>
  <!--begin::Toolbar-->
  <div class="toolbar" v-if="showToolbar">
    <!--begin::Container-->
    <div
        id="kt_toolbar_container"
        :class="{
        'container-fluid': toolbarWidthFluid,
        'container-xxl': !toolbarWidthFluid,
      }"
        class="d-flex flex-stack logbook-header-main"
    >
      <!--begin::Page title-->
      <div
          data-kt-swapper="true"
          data-kt-swapper-mode="prepend"
          data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
          class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
      >
        <!--begin::Title-->
        <div>
          <router-link :to="'/logbook'" aria-label="Return to Logbook">
            <p class="back-to-my-logbook" v-if="isHomePage === false"> {{ $t('toolbar.backTo') }} <b>{{ $t('toolbar.logbook') }}</b></p>
          </router-link>
          <h1 class="d-flex align-items-center text-dark fw-bolder my-1 desktop-title" id="page-title-h1"></h1>
        </div>
        <!--end::Title-->

        <span
            v-if="breadcrumbs"
            class="h-20px border-gray-200 border-start mx-4"
        ></span>

        <!--begin::Breadcrumb-->
        <ul
            v-if="breadcrumbs"
            class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1"
        >
          <li class="breadcrumb-item pe-3">
            <router-link to="/dashboard" class="text-muted text-hover-primary">
              Home
            </router-link>
          </li>
          <li class="breadcrumb-item">
            <span class="bullet bg-gray-200 w-5px h-2px"></span>
          </li>
          <template v-for="(item, index) in breadcrumbs" :key="index">
            <li class="breadcrumb-item text-muted">
              {{ item }}
            </li>
            <li class="breadcrumb-item">
              <span class="bullet bg-gray-200 w-5px h-2px"></span>
            </li>
          </template>
          <li class="breadcrumb-item pe-3 text-dark">
            {{ title }}
          </li>
        </ul>
        <!--end::Breadcrumb-->
      </div>
      <!--end::Page title-->

      <!--begin::Actions-->
      <div>
        <div class='mobile-title'>
          <h1>{{ title }}</h1>
        </div>
        <div class="logbook-header-right" v-if="loggedIn && isHomePage === true">
          <div class="action-options">
            <router-link class='toolbar-router-link book-collection-link' :to="'/collection'">
              <p id='book-collection'>
                <img alt="Book Collection Icon" src="/media/icons/award-solid.svg" :style="{width: '10px', marginRight: '5px', marginBottom: '2px'}"/>
                <span v-html="$t('toolbar.bookCollection')"></span></p>
            </router-link>
            <router-link class='toolbar-router-link more-stats-link' :to="'/stats'">
              <p id='more-stats'>
                <span v-html="$t('toolbar.moreStats')"></span>
              </p>
            </router-link>
          </div>

          <div class="d-flex align-items-center py-1 action-grid rounded">
            <div id="actions-portal"></div>
            <stats-panel/>
          </div>
        </div>
      </div>

      <!-- START:: Temp print disable 1 of 3 -->
      <!--
      <div class='logbook-header-right collection-header' v-if="loggedIn && isCollectionPage === true">
        <p :style="{margin: 0, fontWeight: '600', fontSize: '1.075em'}">{{$t('toolbar.shareCollection')}}</p>
      -->
      <!-- END:: Temp print disable 1 of 3 -->
        <!-- <b class='collection-bttn' @click="saveCollection"> <inline-svg src="/media/icons/save-icon.svg" :style="{marginRight: '5px'}"/> Save Image </b> -->
        <!-- START:: Temp print disable 2 of 3 -->
        <!-- <b class='collection-bttn' @click="printCollection"> {{$t('toolbar.print')}} </b> -->
        <!-- END:: Temp print disable 2 of 3 -->
        <!-- <b class='collection-bttn'> <inline-svg src="/media/icons/copy-icon.svg" :style="{marginRight: '5px'}"/> Copy</b> -->
      <!-- START:: Temp print disable 3 of 3 -->
      <!-- </div> -->
      <!-- END:: Temp print disable 3 of 3 -->
      <!--end::Actions-->

    </div>
    <!--end::Container-->
    <search-bar v-if="isHomePage === true" class='search-bar'/>
  </div>
  <!--end::Toolbar-->
</template>

<script>
import {defineComponent} from "vue";
import {toolbarWidthFluid} from "@/core/helpers/config";
import StatsPanel from "@/app/components/Stats/StatsPanel.vue";
import SearchBar from "@/app/components/Search/SearchBar.vue";

import authenticationService from "@/core/services/AuthenticationService";
import html2canvas from 'html2canvas'
import {jsPDF} from "jspdf";

export default defineComponent({
  name: "Toolbar",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  components: {
    StatsPanel,
    SearchBar
  },
  computed: {
    loggedIn() {
      return authenticationService.isLoggedIn();
    },
    showToolbar() {
      if (
        this.$route.path == '/myirla' ||
        this.$route.name == 'unit' ||
        this.$route.name == 'assignments' ||
        this.$route.name == 'home'
      ) {
        return false
      } else return true
    },
    isHomePage() {
      if (
        this.$route.path == '/home' ||
        this.$route.path == '/logbook'
      ) {
        return true
      } else return false
    },
    isCollectionPage() {
      if (this.$route.path == '/collection') {
        return true
      } else return false
    }
  },
  setup() {
    return {
      toolbarWidthFluid,
    };
  },
  methods: {
    printCollection() {
      window.print();
    },
    saveCollection() {
      let pdf = new jsPDF("p", "mm", "a4");
      let element = document.getElementById('collection-image');
      let width = pdf.internal.pageSize.getWidth()
      let height = pdf.internal.pageSize.getHeight()
      html2canvas(element).then(canvas => {
        let image = canvas.toDataURL('image/jpg');
        pdf.addImage(image, 'JPEG', 0, 0, width, height);
        pdf.save('Collection' + '.pdf')
      });
    }
  }
});
</script>

<style lang="scss">
.toolbar {
  height: auto !important;
  display: flex;
  flex-direction: column;
  margin: 2em;
  padding: 1em !important;
  border-radius: 12px;
  /* Remove chunk if toolbar needs to be position fixed -- also uncomment padding at core/assets/sass/layout/main.scss lines 43, 48, 67, & 72  */
  position: relative !important;
  left: 0 !important;
  margin-bottom: 4em;

  .expand-mobile-menu {
    position: absolute;
    left: 3em;
    top: 3em;
    display: none;
  }

  .logbook-header-main {
    margin-top: 30px;
  }

  .page-title {
    h1 {
      font-size: 32px !important;
      margin-left: 8px;
    }
  }

  .mobile-title {
    display: none;
    font-size: 32px !important;
  }


  .back-to-my-logbook {
    color: #163C64;
    width: fit-content;
    padding: 2px 8px;
    position: relative;
    top: -15px;

    &:hover {
      background-color: #F1F6FC;
      border-radius: 12px;
    }
  }

  .logbook-header-right {
    .action-options {
      display: flex;
      justify-content: flex-end;
      margin: 1em 0 0.5em 0;

      p {
        margin: 0 1em;
      }

      #book-collection {
        color: #714C13;

        .book-collection-icon {
          height: 40px;
          width: 40px;
        }
      }

      #more-stats {
        color: #163C64;
      }

      .toolbar-router-link {
        border-radius: 12px;
        padding: 2px;

        &.book-collection-link {
          &:hover {
            background-color: #FDF4E8;
          }
        }

        &.more-stats-link {
          &:hover {
            background-color: #F1F6FC;
          }
        }
      }
    }



    &.collection-header {
      display: flex;
      align-items: center;

      .collection-bttn {
        border: solid 1px #999;
        border-radius: 4px;
        margin-left: 1em;
        padding: 2px 6px;
        color: #1D5CFE;
        cursor: pointer;
      }
    }

  }

  .search-bar {
    width: 90%;
    margin-top: 1em;
  }

}

@media screen and (max-width: 1150px) {
  .toolbar {
    top: 0 !important;
    padding-top: 55px !important;

    .expand-mobile-menu {
      display: block;
    }
  }
  #kt_toolbar_container {
    justify-content: space-evenly;

    .title {
      display: none !important;
    }

    .mobile-title {
      display: block;
    }
  }
  .desktop-title {
    display: none !important;
  }
}

@media screen and (max-width: 800px) {
  #kt_toolbar_container {
    flex-direction: column;

    .logbook-header-right {
      margin-top: 4em;
    }
  }
}

@media print {
  .toolbar {
    display: none;
  }
}

</style>
