import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "steps-read ms-4"
}
const _hoisted_2 = { class: "steps-number" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.steps != null)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString($props.steps), 1),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('stepsRead.steps', $props.steps)), 1)
      ]))
    : _createCommentVNode("", true)
}