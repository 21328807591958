
import { defineComponent } from "vue";
import ArcTopbar from "@/core/layout/header/Topbar.vue";
import authenticationService from "@/core/services/AuthenticationService";

import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";

export default defineComponent({
  name: "Header",
  props: {
    title: String,
  },
  components: {
    ArcTopbar
  },
  setup() {
    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
    };
  },
  methods: {
    getHeaderColor() {
      if (authenticationService.isLoggedIn()) {
        return '#'+authenticationService.currentUser().profile.bg_color;
      }
    }
  }
});
