

export default {
  props: ['level'],
  computed: {
    levelLabel() {
        return (this.level || '');
    },
    levelClassName() {
      return 'level-' + (this.levelLabel).toLowerCase();
    }
  }
}
