import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search-result" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_book_summary = _resolveComponent("book-summary")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_book_summary, {
      book: $props.book,
      "hide-stats": false
    }, null, 8, ["book"])
  ]))
}