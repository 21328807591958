
import { defineComponent, onMounted } from "vue";
import { ScrollTopComponent } from "@/core/assets/ts/components/ScrollTopComponent";

export default defineComponent({
  name: "ScrollTop",
  components: {},
  setup() {
    onMounted(() => {
      ScrollTopComponent.reinitialization();
    });
  },
});
