
import LevelBadge from "@/app/components/LevelBadge.vue";
import BookCover from "@/app/components/BookCover.vue";
import StepsRead from "@/app/components/StepsRead.vue";
import bookService from "@/app/services/bookService";

export default {
  components: {StepsRead, LevelBadge, BookCover},
  props: ['book', 'hideStats'],
  methods: {
    formatTitle(title){
      return bookService.formatTitle(title);
    },
  },
  computed: {
    readingLevel() {
      return bookService.readingLevel(this.book, this.$i18n.locale);
    },
    hasAccess() {
      return bookService.canLinkToBookshelf(this.book?.bookshelf_sku);
    }
  }
}
