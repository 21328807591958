
import moment from "moment-with-locales-es6";
import MilestoneBar from "@/app/components/Stats/MilestoneBar.vue";
import MilestoneBadge from "@/app/components/Stats/MilestoneBadge.vue";
import GenreChart from '@/app/components/Stats/GenreChart.vue';
import logService from "@/app/services/LogService";
import store from "@/core/store/PersistentStorage";
import bookService from "@/app/services/bookService";
import apiClient from "@/core/services/apiClient";



export default {
  components: {MilestoneBar, MilestoneBadge, GenreChart},
    data() {
    return {
      collapseMedals: false,
      initialSchoolYear: null,
    }
  },
  computed: {
    currentSchoolYearName() {
      return store?.appData?.thisSchoolYear?.schoolYearName;
    },
    initialSchoolYearName() {
      if (logService.schoolYearData == null || logService.schoolYearData.length < 1) return '';
      return logService.schoolYearData[0].schoolYearName || '';
    },
    stepsTodayWidth() {
      return Math.min(Math.trunc((this.stepsToday / 4) * 100)) + '%';
    },
    stepsToday() {
      let today = moment().format('YYYY-MM-DD') + ' 00:00:00';

      let log = [...logService.getCombinedReadingLog()];

      return log.reduce((sum, book) => {
        return sum + (book.log_entries || []).reduce((entrySum, entry) => {
          return entrySum + ((entry.entry_date == today) ? entry.steps : 0);
        }, 0);
      }, 0);
    },
    stepsTotal() {
      return bookService.stepsTotal();
    },
    lifetimeSteps() {
      // Calculate based on combined SP + ARC Reads data.
      return logService.getCombinedReadingLog().reduce((sum, book) => {
        return sum + (book.log_entries || []).reduce((entrySum, entry) => {
          return entrySum + entry.steps;
        }, 0);
      }, 0);
    },
    stepsAwaitingApproval() {
      return bookService.stepsAwaitingApproval();
    },
    streak() {
      return logService.getReadingStreak();
    }
  },
}
